import Styles from './Scoring.scss';

import Template from './Scoring.hbs';
import Score from 'util/Score';
import ScoringButton from './scoringButton/ScoringButton';

export default BaseView.extend({

    events: {
        'click .js-grade-answer-option': 'gradeAnswer'
    },

    /**
     * @param {ResponseModel} response               Most recent response.
     * @param {Boolean} isExam                       If true, response is part of an exam activty, which requires a
     *                                               different type of scoring interface and logic.
     * @param {Array} ranges                         List of possible score values for this response.
     */
    initialize({
        response,
        isExam,
        ranges
    }) {

        this.response = response
        this.ranges = ranges

        _.bindAll(
            this,
            'gradeAnswer'
        );

        this.setElement(Template({ Styles }))

        for (const range of ranges) {
            const score = range[0]
            let label = range[1]
            let color

            if (!isExam) {
                label += '%'
                color = Score.getColor(score)
            }

            this.addChildView(
                new ScoringButton({ score, label, color }),
                this.$el
            )
        }

        this.setScore(this.response.get('score'));

        this.listenTo(
            this.response,
            'change:score',
            () => this.setScore(this.response.get('score'))
        )
    },

    gradeAnswer(e) {
        const currentScore = $(e.currentTarget)
        const currentGrade = currentScore.hasClass('is-active')
            ? -1
            : parseFloat(currentScore.data('score'))

        this.response.updateScore(currentGrade)
        this.response.set('score', currentGrade)
    },

    setScore(score) {

        this.$('.js-grade-answer-option').removeClass('is-active')

        if (score >= 0) {

            // If the value of the score does not match any of the scores in range, find the one in the range that
            // is the most close. Does does not affect the student's real score, only the display of it.
            const scoreRange = _.unzip(this.ranges)[0]
            if (scoreRange.includes(score) === false) {
                const scoreDistances = scoreRange.map(s => Math.abs(score - s))
                score = scoreRange[scoreDistances.lastIndexOf(Math.min(...scoreDistances))]
            }

            this.$(`.js-grade-answer-option[data-score="${score}"]`).addClass('is-active')
        }
    }
});
