<script>
    import Button from 'views/components/button/Button.svelte';
    import Wysiwyg from 'views/components/wysiwyg/Wysiwyg';

    export let recipientUserId
    export let taskId
    export let activityModel
    export let conversation = null
    export let feedbackSuggestion = null
    export let callback

    let wysiwyg
    let sendInProgress
    let saveToStorageFunction

    const groupId = recipientUserId === 0 ? activityModel.getGroupModel().id : null
    const storageId = `feedback:${recipientUserId}:${activityModel.id}:${taskId}`;

    const recipientName = recipientUserId === 0 ? window.i18n.gettext('Whole group') :
        Backbone.Collection.students.get(recipientUserId).first_name_last_name()

    function sendFeedback() {
        if (Backbone.Model.user.get('is_demo')) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Demo accounts are not allowed to send feedback'),
                'warning'
            )
            Backbone.View.sidebar.closeSidebar()
            return
        }

        const messageContent = wysiwyg.getContent()
        if (!messageContent) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You did not enter a message.'),
                'info'
            )
            return
        }

        sendInProgress = true
        jQuery.post({
            url: '/feedback/messages/create.json',
            data: {
                message: messageContent,
                conversation_id: conversation?.id || null,
                recipient_user_id: recipientUserId,
                group_id: groupId,
                activity_id: activityModel.id,
                task_id: taskId
            },
            success: (response) => {
                if (response.err_code) {
                    switch (response.err_code) {
                        case 38404:
                            // We are trying to create a message on a conversation_id which not exists
                            window.sentry.withScope(scope => {
                                scope.setExtra('group_id', groupId)
                                scope.setExtra('activity_id', activityModel.id)
                                scope.setExtra('task_id', taskId)
                                scope.setExtra('recipient_user_id', recipientUserId)
                                scope.setExtra('conversation_id', conversation?.id)
                                window.sentry.captureMessage('Conversation with does not exists')
                            });

                            Backbone.View.layout.openStatus(
                                window.i18n.gettext('Something went wrong while sending feedback')
                            );

                            break;
                        case 38406:
                            // We are trying to create a conversatin with a context that already exists
                            window.sentry.withScope(scope => {
                                scope.setExtra('group_id', groupId)
                                scope.setExtra('activity_id', activityModel.id)
                                scope.setExtra('task_id', taskId)
                                scope.setExtra('recipient_user_id', recipientUserId)
                                window.sentry.captureMessage('Creating conversation with an already existing context')
                            });

                            Backbone.View.layout.openStatus(
                                window.i18n.gettext('Something went wrong while sending feedback')
                            );

                            break;
                        default:
                            window.sentry.withScope(scope => {
                                scope.setExtra('group_id', groupId)
                                scope.setExtra('activity_id', activityModel.id)
                                scope.setExtra('task_id', taskId)
                                scope.setExtra('recipient_user_id', recipientUserId)
                                window.sentry.captureMessage('Unknown error while sending feedback')
                            });

                            Backbone.View.layout.openStatus(
                                window.i18n.gettext('Something went wrong while sending feedback')
                            );
                    }

                    return;
                }

                saveToStorageFunction?.cancel()
                window.sessionStorage.removeItem(storageId)

                // If a conversation is already present, update it with the new one from backend
                if (conversation) {
                    const index = activityModel.feedback.findIndex(element => element.id === conversation.id)
                    activityModel.feedback[index] = response
                // Otherwise, append the newly created conversation to the array of conversations
                } else {
                    activityModel.feedback.push(response)
                }
                callback(response)
                Backbone.View.sidebar.closeSidebar()
            },
            complete: () => {
                sendInProgress = false
            }
        })
    }

    function clearInput() {
        wysiwyg.clearInput();
        wysiwyg.setFocus();
    }

    function addWysiwig(element) {
        wysiwyg = new Wysiwyg({
            content: window.sessionStorage.getItem(storageId) || feedbackSuggestion || '',
            autoFocus: true,
            buttonsPreset: 'sendFeedback'
        })

        element.append(wysiwyg.el)

        saveToStorageFunction = _.debounce(() => {
            const editorContent = wysiwyg.getContent()

            if (!editorContent && window.sessionStorage.getItem(storageId) === null) {
                return
            }

            if (editorContent === '') {
                window.sessionStorage.removeItem(storageId);
                return
            }

            window.sessionStorage.setItem(storageId, editorContent);
        }, 800)

        wysiwyg.on('change', saveToStorageFunction);
    }
</script>

<div class="container">
    <div class="send-to">
        {window.i18n.gettext('Send to')} <strong>{recipientName}</strong>
    </div>

    <div use:addWysiwig></div>

    <div class="buttons">
        <Button
            disabled={sendInProgress}
            hasSpinner={sendInProgress}
            label={window.i18n.gettext('Send')}
            inline={true}
            callback={sendFeedback}>
        </Button>

        {#if feedbackSuggestion}
            <Button

                disabled={sendInProgress}
                hasSpinner={sendInProgress}
                label={window.i18n.gettext('Clear')}
                inline={true}
                callback={clearInput}
                theme="secondary">
            </Button>
        {/if}
    </div>
</div>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px;
    }

    .send-to {
        @include large-text;
    }

    .buttons {
        display: flex;
        gap: 20px;
        justify-content: space-between;
    }
</style>
