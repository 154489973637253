import Styles from './Student.scss';

import Template from './Student.hbs';
import ResponseModel from 'models/ResponseModel';
import CheckAnswerStudentColumnView from 'views/components/answers/student/column/Column';
import Conversation from 'views/components/feedback/feedbackWorkOn/Conversation.svelte';
import Button from 'views/components/button/Button'

export default class Student extends BaseView {

    /**
     * initialize
     *
     * this.model {StudentModel}                     Student information
     *
     * @param {ResponseModel} response               Most recent response.
     * @param {ResponseModel} responseOriginal       Original response.
     * @param {Task} taskView                        Parent task view this answer view is related to.
     * @param {Answer} parentView                    Parent Answer view.
     * @param {Boolean} isInPresentMode              If activity is in presentation mode
     */
    initialize({
        response,
        responseOriginal,
        taskView,
        parentView,
        isInPresentMode,
    }) {

        _.bindAll(this,
            'displayFeedback',
            'renderStudentAnswer',
            'renderStudentAnswerView',
        )

        this.isInPresentMode = isInPresentMode;
        this.taskView = taskView;
        this.parentView = parentView;
        this.activityModel = this.taskView.work_on.model;

        const isExamAnswerArchive = !!(taskView.isPreview && this.activityModel.isExam())

        // init response
        if (response) {
            response.set('original', 0)
        } else {
            response = new ResponseModel({
                user_id: this.model.id,
                task_id: this.taskView.model.id,
                activity_id: this.activityModel.id,
                score: -1,
                original: 0
            });
            this.activityModel.responses.add(response);
        }

        // init original response
        if (responseOriginal) {
            responseOriginal.set('original', 1);
        } else {
            responseOriginal = new ResponseModel({
                user_id: this.model.id,
                task_id: this.taskView.model.id,
                activity_id: this.activityModel.id,
                score: -1,
                original: 1
            });
            this.activityModel.responses_original.add(responseOriginal);
        }

        const shouldShowFeedbackButton =
            this.activityModel.get('type') !== 'presentation' &&
            !this.taskView.isPreview &&
            !isInPresentMode

        /**
         * pass studentId and taskId. in the progress screen this can be used
         * to target and hide graded answers
         */
        this.setElement(Template({
            studentId: this.model.id,
            taskId: this.taskView.model.id,
            last_name_first_name: this.model.last_name_first_name(),
            shouldShowFeedbackButton,
            Styles,
            showStudentName: !parentView.isCalledFromProgress && !isExamAnswerArchive,
            isCalledFromProgress: parentView.isCalledFromProgress,
            reviewAssistantJustification: responseOriginal.get('review_assistant_justification')
        }));

        if (isInPresentMode) {
            this.toggleStudentName(false)
        }

        if (!isInPresentMode && responseOriginal.get('review_assistant_justification')) {

            const reviewAssistantScore = (
                Math.round(
                    responseOriginal.get('review_assistant_score') * this.activityModel.getAttainableExamPoints(responseOriginal.get('task_id')) * 2
                ) / 2
            )

            // We want to show 0.5 as 0.5 point (half a point) instead of 0.5 points. Therefore
            // if the value is 0.5, act as it is singular for ngettext
            const plurality = reviewAssistantScore === 0.5 ? 1 : reviewAssistantScore;

            const reviewAssistantButton = this.addChildView(
                new Button({
                    label: window.i18n.sprintf(
                        window.i18n.ngettext(
                            'Review assistant: %s point',
                            'Review assistant: %s points',
                            plurality
                        ),
                        reviewAssistantScore.toLocaleString()
                    ),
                    theme: 'transparent',
                    icon: 'aissistant',
                    callback: () => this.toggleReviewAssistant()
                }),
                '.js-student-answer-footer'
            )

            this.addChildView(
                new Button({
                    label: window.i18n.sprintf(
                        window.i18n.ngettext(
                            'Give %s point',
                            'Give %s points',
                            plurality
                        ),
                        reviewAssistantScore.toLocaleString()
                    ),
                    theme: 'primary',
                    callback: () => {
                        responseOriginal.set('score', responseOriginal.get('review_assistant_score'))

                        this.toggleReviewAssistant()

                        $.post(`/review_assistant/score/${responseOriginal.get('review_assistant_id')}.json`)
                    }
                }),
                '.js-review-assistant-actions'
            )

            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Remove suggestion'),
                    theme: 'secondary',
                    callback: () => {
                        this.toggleReviewAssistant()
                        TweenMax.to(
                            this.$('.js-review-assistant'),
                            {
                                left: 100,
                                duration: 0.2,
                            }
                        );

                        reviewAssistantButton.destroy()

                        $.ajax({
                            url: `/review_assistant/score/${responseOriginal.get('review_assistant_id')}.json`,
                            type: 'DELETE'
                        })
                    }
                }),
                '.js-review-assistant-actions'
            )

            // Since the default is hidden, only toggle visibility according to the user setting when the
            // response isn't already scored by the teacher. If the answer is already scored by the teacher
            // we consider this response "done". So we don't need the review assistant anymore.
            if (responseOriginal.get('score') === undefined || responseOriginal.get('score') === -1) {
                this.toggleReviewAssistantForUserSetting();

                this.listenTo(
                    Backbone.Model.user,
                    'changedReviewAssistantSetting',
                    () => this.toggleReviewAssistantForUserSetting()
                );
            }
        }

        if (!isInPresentMode && shouldShowFeedbackButton) {
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Send feedback'),
                    theme: 'transparent',
                    icon: 'feedback-filled',
                    callback: () => responseOriginal.get('review_assistant_justification')
                        ? this.onClickSendFeedback(responseOriginal.get('review_assistant_justification'))
                        : this.onClickSendFeedback()
                }),
                '.js-student-answer-footer'
            )
        }

        const areReponseRevisionsEqual =
            response.get('responses_rev_id') ===
            responseOriginal.get('responses_rev_id')

        // Render original answers if one of these is true:
        // - the activity is an exam
        // - we are generating an exam archive
        // - the original revision is different than the latest revision
        if (this.activityModel.isExam() || isExamAnswerArchive || !areReponseRevisionsEqual) {
            /*
             *
             * add original answer
             *
             */
            const originalCheckAnswerStudentColumnView = this.renderStudentAnswer(
                responseOriginal,
                areReponseRevisionsEqual,
            )

            // Add a class to non-exam answers so they can be hidden with the 'original answers' setting
            if (!this.activityModel.isExam() && !isExamAnswerArchive) {
                originalCheckAnswerStudentColumnView.$el.addClass('js-non-exam-original-answer');
            }
            originalCheckAnswerStudentColumnView.$('.js-grade-answer').addClass('js-grade-for-original');

        }

        // Render latest answers if:
        // - the activity is not an exam
        // - we are not generating an exam archive
        if (!this.activityModel.isExam() && !isExamAnswerArchive) {

            /*
             *
             * add latest answer
             *
             */

            const latestCheckAnswerStudentColumnView = this.renderStudentAnswer(response, areReponseRevisionsEqual)

            latestCheckAnswerStudentColumnView.$('.js-grade-answer').addClass('js-grade-for-recent');

        }

        // Display feedback only for the activity view, not in the author and not presentation
        if (
            !this.taskView.practice &&
            APPLICATION !== 'author' &&
            this.activityModel.get('type') !== 'presentation' &&
            !this.taskView.isPreview &&
            !isInPresentMode
        ) {
            this.displayFeedback();
        }

    }

    toggleReviewAssistantForUserSetting() {
        if (ACL.checkRole(ACL.roles.TEACHER)
            && (Backbone.Model.user.getSetting('showReviewAssistant') ?? true)
        ) {
            this.showReviewAssistant();
        } else {
            this.hideReviewAssistant();
        }
    }

    toggleReviewAssistant() {
        if (this.$('.js-review-assistant').hasClass(Styles.open)) {
            this.hideReviewAssistant();
        } else {
            this.showReviewAssistant();
        }
    }

    hideReviewAssistant() {
        TweenMax.to(
            this.$('.js-review-assistant'),
            {
                opacity: 0,
                height: 0,
                marginTop: 0,
                marginBottom: 0,
                duration: 0.2,
                onComplete: () => this.$('.js-review-assistant').hide().removeClass(Styles.open)
            }
        )
    }

    showReviewAssistant() {
        this.$('.js-review-assistant').show()

        TweenMax.fromTo(
            this.$('.js-review-assistant'),
            {
                opacity: 0,
                height: 0,
                marginTop: 0,
                marginBottom: 0,
            },
            {
                opacity: 1,
                height: 'auto',
                duration: 0.2,
                marginTop: 0,
                marginBottom: 8,
                onComplete: () => this.$('.js-review-assistant').addClass(Styles.open)
            }
        );
    }

    toggleStudentName(state) {
        this.toggleNameButton = this.addChildView(new Button({
            label: state ? window.i18n.gettext('Hide name') : window.i18n.gettext('Show name'),
            icon: state ? 'eye-off' : 'eye',
            callback: () => {
                this.toggleNameButton.destroy()
                this.toggleStudentName(!state)
            },
            theme: 'transparent',
        }), '.js-student-name', 'after')
        this.el.querySelector('.js-student-name').style.visibility = state ? 'visible' : 'hidden'
    }

    renderStudentAnswer(response, areReponseRevisionsEqual) {
        const studentAnswer = this.taskView.templateView.getStudentAnswer(response)

        // Student answer could be rendered as a view or a Promise of a view instead of HTML or a plain string.
        const studentAnswerIsView = (studentAnswer instanceof BaseView) || (studentAnswer instanceof Promise)

        // Create student answer column view
        const checkAnswerStudentColumnView = this.addChildView(
            new CheckAnswerStudentColumnView({
                activityModel: this.activityModel,
                response,
                // When the studentAnswerIsView is true set givenAnswer to a string, an empty
                // student answer will be used, which will be overridden by the studentAnswer view
                givenAnswer: studentAnswerIsView ? '' : studentAnswer,
                areReponseRevisionsEqual,
                isInPresentMode: this.isInPresentMode
            }),
            '.js-answer-columns-holder'
        );

        if (studentAnswerIsView) {
            this.renderStudentAnswerView(studentAnswer, checkAnswerStudentColumnView)
        }

        // If user is teacher and grading mode is on, show the answer grading for the original/latest answers.
        if (
            Backbone.Model.user.get('is_teacher') &&
            Backbone.Model.user.getSetting('is_grading')
        ) {
            checkAnswerStudentColumnView.$('.js-grade-answer').css('display', 'flex')
        }

        return checkAnswerStudentColumnView
    }

    renderStudentAnswerView(view, checkAnswerStudentColumnView) {
        if (view instanceof Promise) {
            view.then((resolvedView) => {
                this.renderStudentAnswerView(resolvedView, checkAnswerStudentColumnView)
            })
        } else {

            checkAnswerStudentColumnView.addChildView(
                view,
                '.js-student-answer',
                'html'
            );

            // Listen to the toggleOriginalAnswer from the parentView and pass it to student answer view.
            this.listenTo(this.parentView, 'toggleOriginalAnswer', (state) => {
                view.trigger('toggleOriginalAnswer', state)
            })
        }
    }

    onClickSendFeedback(feedbackSuggestion) {
        this.parentView.onSendFeedback(this.model.id, this, feedbackSuggestion);
    }

    /**
     * displayFeedback - displays feedback given to this student on this task.
     */
    displayFeedback() {
        this.destroyChildViewsOfInstance(Conversation);

        for (const conversation of this.activityModel.feedback) {
            if (conversation.conversation_context?.task_id !== this.taskView.model.id) {
                continue
            }

            // If student is participant in conversation
            if (conversation.participants.some(({user_id}) => user_id === this.model.id)) {
                this.addSvelteChildView(
                    '.js-feedback',
                    Conversation,
                    {
                        conversation,
                        activityModel: this.activityModel
                    }
                )
            }
        }
    }
}
